import CommonLayout from '../../layouts/CommonLayout';
import ReportCommonLayout from '../../../components/reportLayout/ReportCommonLayout';
import CodeLayout from '../../../components/CodeLayout';

export const TicTacToeGameReport1 = () => {
  return (
    <CommonLayout>
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-12">
            <h3 className="font-weight-light mt-4 text-black">Reactでの○×ゲームの作成①</h3>
          </div>
        </div>

        {
          ReportCommonLayout(
            "以下の方向け",
            <ul className="mt-3">
              <li>Reactの環境構築済みの方</li>
              <li>React初心者の方</li>
              <li>Reactの記述を関数型で行いたい方 <br /> → クラス型で記述をしたい方は参考サイトにサンプルがあります</li>
            </ul>
          )
        }

        {
          ReportCommonLayout(
            "参考サイト",
            <ul className="mt-3">
              <li>
                <a href="https://ja.reactjs.org/tutorial/tutorial.html">
                  チュートリアル：React の導入 – React
                </a>
              </li>
              <li>
                <a href="/reports/tic_tac_toe_game/2">Reactでの○×ゲームの作成②</a>
              </li>
            </ul>
          )
        }

        {
          ReportCommonLayout(
            "作成の流れ",
            <ul className="mt-3">
              <li>ボードの作成</li>
              <li>ボタンの有効化　　　　今回↑</li>
              -------------------------------------------
              <li>プレイヤーの追加　　　次回↓</li>
              <li>ゲームの勝敗の判定</li>
            </ul>
          )
        }

        {
          ReportCommonLayout(
            "ボードの作成",
            <div className="mt-3">
              <p>
                ここでは、ボードの作成を行っていきます。<br />
                はじめに○×ゲーム作成のためのファイルを作成します。私の場合は、"TicTacToeGame.js"としました。<br />
                作成後は以下のように、関数型で基礎の枠組みを作成していきます。
              </p>
              {
                CodeLayout(
                  `
  export const TicTacToeGame = () => {
    return (
      <div className="">

      </div>
    )
  }
                  
  export default TicTacToeGame;
`
                )
              }
              <p>
                次に、○×ゲームの枠を作成します。<br />
                参考サイトの「チュートリアル：React の導入 – React」では、Squareクラスを作成していますが本ブログでは、配列を用いて作成していきます。
                また今後のゲームを作成する上でクリックをする必要があるため、ボタンを用いて作成していきます。
                下記のコードを記述後は、3×3のマスにに0~8の数字が入っていれば大丈夫です!(ボタンはクリックできますが反応しません)
              </p>
              {
                CodeLayout(
                  `
  return (
    <div className="">
      {
        Array(3).fill(0).map((row, rowIndex) => {
          return (
            <div className="row" key={rowIndex}>
              {
                Array(3).fill(0).map((col, colIndex) => {
                  return (
                    <button className="square"
                      key={rowIndex * 3 + colIndex}
                    >
                      {rowIndex * 3 + colIndex}
                    </button>
                  )
                })
              }
            </div>
          )
        })
      }
    </div>
  )
`
                )
              }
            </div>
          )
        }

        {
          ReportCommonLayout(
            "ボタンの有効化",
            <div className="mt-3">
              <p>
                ここでは、作成したボードのボタンの有効化を行っていきます。<br />
                はじめにボタンをクリックした時に、そのボタンの番号がコンソールに表示される処理を作成します。<br />
                以下のように、handleClick関数を作成し、buttonにonClickを追加します。
              </p>
              {
                CodeLayout(
                  `
  export const TicTacToeGame = () => {
    const handleClick = (i) => {
      console.log(i);
    }
  ・
  ・
  ・
    <button className="square"
      key={rowIndex * 3 + colIndex}
      onClick={()=>{handleClick(rowIndex * 3 + colIndex)}}
    >
`
                )
              }
              <p>
                次にクリックしたマスに○を表示できるように変更していきます。<br />
                現在のボードの状態を把握するために新たに"board"という変数を定義して使用していきます。<br />
                board変数には初期値として9マスの配列を作り、nullを入れておきます。
                また、今後setStateによって値を入れるためsetBoardもこのときに定義しておきます。
              </p>
              {
                CodeLayout(
                  `
  const [board, setBoard] = useState(Array(3 ** 2).fill(null));
`
                )
              }
              <p>
                board変数作成後は、表示されている箇所とhandleClick関数の変更をしていきます<br />
                ボタン内は、これまで表示していた番号のboardの中身を表示するようにします。<br />
                handleClick関数では、現在のboardの中身を異なる変数に入れ、ボタンを押された番号の中身を"○"に変更しsetBoardでboardの中身を変更します。
              </p>
              {
                CodeLayout(
                  `
  <button>
    {board[rowIndex * 3 + colIndex]}
  </button>
`
                )
              }
              {
                CodeLayout(
                  `
  const handleClick = (i) => {
    let tempBoard = board;
    tempBoard[i] = "○";
    setBoard(tempBoard);
  }
`
                )
              }
              <p>
                通常あらばこの段階でボタンの中身が"○"になる予定ですが、setStateが非同期なためか私は上手く反映されません。<br />
                次の「Reactでの○×ゲームの作成②」でこちらの解決と○×ゲームの完成をしていきたいと思います。
              </p>
            </div>
          )
        }

        {
          ReportCommonLayout(
            "最後に",
            <p>
              これでReactでの○×ゲームの作成①は以上となります。<br />
              続きは以下の記事になりますのでぜひご覧ください！<br />
              <a href="/reports/tic_tac_toe_game/2">Reactでの○×ゲームの作成②</a>
            </p>
          )
        }
      </div>
    </CommonLayout>
  )
}

export default TicTacToeGameReport1;