import CommonLayout from '../../layouts/CommonLayout';
import ReportCommonLayout from '../../../components/reportLayout/ReportCommonLayout';
import CodeLayout from '../../../components/CodeLayout';

export const Tutorial2 = () => {
  return (
    <CommonLayout>
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-12">
            <h3 className="font-weight-light mt-4 text-black">React チュートリアル②<br />～React Hooks編～</h3>
          </div>
        </div>

        {
          ReportCommonLayout(
            "以下の方向け",
            <ul className="mt-3">
              <li><a href='/reports/react/tutorial/1'>React チュートリアル①</a>で環境構築済みの方</li>
              <li>React Hooksの使用例を知りたい方</li>
            </ul>
          )
        }

        {
          ReportCommonLayout(
            "参考サイト",
            <ul className="mt-3">
              <li>
                <a href="https://ja.reactjs.org/docs/hooks-intro.html">
                  フックの導入
                </a>
              </li>
            </ul>
          )
        }

        {
          ReportCommonLayout(
            "今回の流れ",
            <div className="mt-3">
              <ul>
                <li>React Hooksとは</li>
                <li>useStateの使用例</li>
                <li>useEffectの使用例</li>
                <li>useContextの使用例</li>
              </ul>
              今回は、React Hooksの説明と簡単な使用例をまとめます。<br />
            </div>
          )
        }

        {
          ReportCommonLayout(
            "React Hooksとは",
            <div className="mt-3">
              <p>
                <a href="https://ja.reactjs.org/docs/hooks-intro.html">Reactの公式サイト</a>では、以下のように説明されています<br />
                <blockquote className="blockquote mt-2">
                  フック (hook) は React 16.8 で追加された新機能です。state などの React の機能を、クラスを書かずに使えるようになります。
                </blockquote>
                これまで、Reactの機能を使う際にはクラス型を利用する必要がありました。<br />
                そのため、関数型で記述をしているシステムであった場合書き直す必要がありました。<br />
                これを解決するために「Reactの機能を関数型でも使用できるように新しい機能を追加した」ということです。
              </p>
            </div>
          )
        }

        {
          ReportCommonLayout(
            "useStateの使用例",
            <div className="mt-3">
              {
                CodeLayout(
                  `
  import { useState } from 'react';

  const Sample = () => {
    // 初期値
    const [name, setName] = useState("太郎");

    // 値の更新
    setName("花子");

    return (
      <div>
        {name}
      </div>
    )
  }
`
                )
              }
            </div>
          )
        }

        {
          ReportCommonLayout(
            "useEffectの使用例",
            <div className="mt-3">
              {
                CodeLayout(
                  `
  import { useState, useEffect } from 'react';

  const Sample = () => {
    // 初期値
    const [name, setName] = useState("太郎");

    useEffect(() => {
      setName("花子");
    }, []);

    return (
      <div>
        {name}
      </div>
    )
  }
`
                )
              }
            </div>
          )
        }

        {
          ReportCommonLayout(
            "最後に",
            <p>
              「React チュートリアル② ～React Hooks編～」は以上となります。<br />
              私自身もReact Hooksについて理解できていない部分も多く、更新できることも多いため今後も更新していきたいと思います。<br />
              今回記事を読んで頂いた方の参考になれば嬉しいです。<br />
              また、今回の内容でわからない点や今後取り上げてほしい内容等あれば、お問い合わせからお願いします。
            </p>
          )
        }
      </div>
    </CommonLayout>
  )
}

export default Tutorial2;