import React, { useState, useEffect } from 'react';
import CommonLayout from '../layouts/CommonLayout';

export const TicTacToeGame = () => {
  const arrayLength = 3;
  const [nextPlayer, setNextPlayer] = useState("○");
  const [winnerPlayer, setWinnerPlayer] = useState(null);
  const [board, setBoard] = useState([]);

  useEffect(() => {
    setNextPlayer("○");
    setBoard(Array(arrayLength ** 2).fill(null));
  }, []);

  const handleClick = (i) => {
    let square = board;
    square[i] = nextPlayer;
    if (CalculateWinner(square)) {
      setWinnerPlayer(nextPlayer);
    } else {
      nextPlayer === "○" ? setNextPlayer("×") : setNextPlayer("○");
    }
    setBoard(square);
  }

  const handleClickReset = () => {
    setBoard(Array(arrayLength ** 2).fill(null));
    setNextPlayer("○");
    setWinnerPlayer(null);
  }

  return (
    <CommonLayout>
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-7">
            <h3 className="font-weight-light mt-4 text-black">○×ゲーム</h3>
          </div>
        </div>
        <div className="row justify-content-center text-center">
          <div className="col-7">
            <h3 className="font-weight-light mt-4 text-black">
              {
                winnerPlayer ? 
                `プレイヤー${winnerPlayer}の勝利です`:
                `次のプレイヤーは${nextPlayer}です`
              }
              
            </h3>
          </div>
        </div>
        <div className="">
          {
            Array(arrayLength).fill(0).map((row, rowIndex) => {
              return (
                <div className="row justify-content-center" key={rowIndex}>
                  {
                    Array(arrayLength).fill(0).map((col, colIndex) => {
                      return (
                        <button className="square"
                          key={rowIndex * arrayLength + colIndex}
                          onClick={() => { handleClick(rowIndex * arrayLength + colIndex) }}
                          disabled={board[rowIndex * arrayLength + colIndex] || winnerPlayer}
                        >
                          {board[rowIndex * arrayLength + colIndex]}
                        </button>
                      )
                    })
                  }
                </div>
              )
            })
          }
        </div>
        <div className="row justify-content-center text-center mt-4">
          <div className="col-4">
            <button onClick={handleClickReset}>Reset</button>
          </div>
        </div>
      </div>
    </CommonLayout>
  )
}

const CalculateWinner = (squares) => {
  const lines = [
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
    [0, 3, 6],
    [1, 4, 7],
    [2, 5, 8],
    [0, 4, 8],
    [2, 4, 6],
  ];
  for (let i = 0; i < lines.length; i++) {
    const [a, b, c] = lines[i];
    if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
      return true;
    }
  }
  return false;
}