import CommonLayout from '../../layouts/CommonLayout';
import ReportCommonLayout from '../../../components/reportLayout/ReportCommonLayout';
import CodeLayout from '../../../components/CodeLayout';

export const Axios = () => {
  return (
    <CommonLayout>
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-12">
            <h3 className="font-weight-light mt-4 text-black">【React, JavaScript】axiosの導入＆使い方</h3>

          </div>
        </div>

        <div className="row justify-content-center text-start mt-3">
          <div className="col-10">
            React, JavaScriptでのaxiosの導入＆使い方をまとめました。<br />
            これからAPI通信をしたい方は参考にしてください。<br />
            また<a href="https://qiita.com/ksh-fthr/items/2daaaf3a15c4c11956e9">こちらの記事</a>を参考にさせて頂きましたので、こちらの記事も参考にして下さい。
          </div>
        </div>

        {
          ReportCommonLayout(
            "目次",
            <ul className="mt-3">
              <li>axiosの説明</li>
              <li>axiosの導入</li>
              <li>axiosの使用方法</li>
            </ul>
          )
        }

        {
          ReportCommonLayout(
            "axiosの説明",
            <div className="mt-3">
              <a href="https://axios-http.com/docs/intro">axiosの公式サイト</a>によると以下のように書かれています。
              <blockquote className="blockquote">
                "Axios is a promise-based HTTP Client for node.js and the browser.
                It is isomorphic (= it can run in the browser and nodejs with the same codebase).
                On the server-side it uses the native node.js http module, while on the client (browser) it uses XMLHttpRequests."
              </blockquote>
              <p>
                ...英語だとよくわからないので日本語に訳しますね(笑)以下がGoogle翻訳で日本語訳した文章になります。
              </p>
              <blockquote className="blockquote">
                "Axiosは、node.jsおよびブラウザー用のPromiseベースのHTTPクライアントです。
                同形です（=同じコードベースのブラウザーとnodejsで実行できます）。
                サーバー側ではネイティブのnode.js httpモジュールを使用し、クライアント（ブラウザー）ではXMLHttpRequestsを使用します。"
              </blockquote>
              つまり簡単に説明するとHTTP通信をするための機能を持っているということです。
            </div>
          )
        }

        {
          ReportCommonLayout(
            "axiosの導入",
            <div className="mt-3">
              <p>
                以下のコマンドを入力し、パッケージの追加をします。
              </p>
              {
                CodeLayout(
                  `
  // npmの場合
  npm install axios

  // yarnの場合
  yarn add axios
`
                )
              }
            </div>
          )
        }

        {
          ReportCommonLayout(
            "axiosの使用方法",
            <div className="mt-3">
              <p>
                始めに、GETリクエストの送り方を紹介します。<br />
                GETリクエストとは、情報を取得するためのリクエストとなります。そのため、後述するPOSTと異なりリクエストに必要な情報の数が限られます。<br />
                GETリクエストは以下のような形で送ります。
              </p>
              {
                CodeLayout(
                  `
  axios({
    method: 'get',
    url: 'https://example.com',
    responseType: 'code'
  });
  // or
  axios.get('https://example.com', {responseType: 'code'});
`
                )
              }
              <p>
                次に、POSTリクエストの送り方を紹介します。<br />
                POSTリクエストとは、先ほどのGETリクエストとは異なり、情報の操作を行うリクエストなります<br />
                例えば、formタグで囲まれた値をデータベースに送り、値の追加・変更をすると思いますが、そのサーバーへのリクエストがこちらのPOSTリクエストになります。
                POSTリクエストは以下のような形で送ります。
              </p>
              {
                CodeLayout(
                  `
  axios({
    method: 'post',
    url: 'https://example.com',
    data: {
      firstName: 'Fred',
      lastName: 'Flintstone'
    }
  });
  // or
  axios.post('https://example.com', {
    data: {
      firstName: 'Fred',
      lastName: 'Flintstone'
    }
  });
`
                )
              }
            </div>
          )
        }
      </div>
    </CommonLayout>
  )
}

export default Axios;