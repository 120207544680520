import React, { useState, useEffect } from 'react';
import CommonLayout from '../layouts/CommonLayout';
import './Main.css';
import axios from 'axios';

import * as Constant from '../../constant';

export const Application = () => {
  const [appList, setAppList] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const result = await getAppList();
      setAppList(result.appList)
    }

    fetch();
  }, []);

  return (
    <CommonLayout>
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-md-8">
            <h1 className="font-weight-light mt-4 text-black">作成したWebアプリ一覧</h1>
          </div>
        </div>

        <div className="row justify-content-center mt-3">
          {appList.map((row, index) => {
            return (
              <div className="col-10 col-md-5 col-xl-3 mt-3" key={index}>
                <div className="card">
                  <div className="card-body application-title">
                    <a href={"/applications/" + row.Path}>
                      <h5 className="card-title">{row.Name}</h5>
                    </a>
                    <p className="card-text">{row.Summary}</p>
                  </div>
                  <ul className="list-group list-group-flush app-list">
                    {
                      row.Reports.map((report, reportIndex) => {
                        return (
                          <li className="list-group-item" key={reportIndex}>
                            <a href={`/reports/${row.url}/${reportIndex + 1}`}>{report}</a>
                          </li>
                        )
                      })
                    }
                  </ul>
                </div>
              </div>
            )
          })}

        </div>
      </div>
    </CommonLayout>
  );
}

const getAppList = async () => {
  return await axios.get(`${Constant.LambdaApiGateWay}/applications`)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      console.log(err);
    });
}

export default Application;