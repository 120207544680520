import React, { useEffect, useState } from 'react';
import CommonLayout from '../layouts/CommonLayout';
import axios from 'axios';
import moment from 'moment';

import * as Constant from "../../constant";

export const Top = () => {
  const [reportList, setReportList] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const result = await getReportList();
      if(result) setReportList(result.reportList);
    }

    fetch();
  }, []);

  return (
    <CommonLayout>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-10 top-header d-flex align-items-center justify-content-center">
            <h2 className="text-white text-center">
              一緒に学び成長していく場<br />
              おかぴーブログ
            </h2>
          </div>
        </div>
        <div className="row justify-content-center mt-5">
          <div className="col-10">
            <h2><span className="badge rounded-pill">新着記事</span></h2>
            <div className="col-13 mt-3">
              <div className="card">
                <div className="card-body">
                  {
                    reportList.map((row, index) => {
                      return (
                        <div className="row mt-2" key={index}>
                          <div className="col-12 col-md-3"><strong>{moment(new Date(row.Date).toISOString()).format("YYYY年M月D日")}</strong></div>
                          <div className="col-12 col-md-9"><a href={"/reports/" + row.Path}>{row.Title}</a></div>
                        </div>
                      )
                    })
                  }
                  <div className="text-end">
                    <a href="/all_reports">{'>>>'}記事一覧はこちら</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center mt-4">
          <div className="col-10">
            <h2><span className="badge rounded-pill">はじめての方はこちら</span></h2>
          </div>
          <div className="col-10 col-md-5 mt-3">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Application</h5>
                <p className="card-text">
                  こちらから作成したWebアプリケーションを見ることができます。
                </p>
                <div className="text-end">
                  <a href="/applications" className="btn btn-primary">Go Application Page</a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-10 col-md-5 mt-3">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">About</h5>
                <p className="card-text">
                  こちらから当ブログの詳細や運営者情報等を見ることができます。<br />
                </p>
                <div className="text-end">
                  <a href="/about" className="btn btn-primary">Go About Page</a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-10 col-md-5 mt-3">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Report</h5>
                <p className="card-text">
                  こちらからIT知識やチュートリアル、プログラムエラーの解決方法等を見ることができます。<br />
                </p>
                <div className="text-end">
                  <a href="/reports" className="btn btn-primary">Go Report Page</a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-10 col-md-5 mt-3">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Contact</h5>
                <p className="card-text">
                  疑問点や気になる事項がございましたら、こちらからお問い合わせすることができます。
                </p>
                <div className="text-end">
                  <a href="/contact" className="btn btn-primary">Go Contact Page</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CommonLayout>
  );
}

const getReportList = async () => {
  const options = {
    quantity: Constant.TopPageReportQuantity
  };

  return await axios.get(`${Constant.LambdaApiGateWay}/reports`, {params: options})
    .then(res => {
      return res.data;
    })
    .catch(err => {
      console.log(err);
      return false;
    });
}

export default Top;