import CommonLayout from '../../layouts/CommonLayout';
import ReportCommonLayout from '../../../components/reportLayout/ReportCommonLayout';
import CodeLayout from '../../../components/CodeLayout';

export const AddressFormReport2 = () => {
  return (
    <CommonLayout>
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-12">
            <h3 className="font-weight-light mt-4 text-black">
              Reactでの住所自動入力②<br />
              全ソースコード
            </h3>
          </div>
        </div>

        {
          ReportCommonLayout(
            "以下の方向け",
            <ul className="mt-3">
              以下のサイトで解説しておりますので、解説付きを読みたい方は以下のサイトをご覧ください。
              <li>
                <a href="/reports/address_form/1">Reactでの住所自動入力①</a>
              </li>
            </ul>
          )
        }

        {
          ReportCommonLayout(
            "参考サイト",
            <ul className="mt-3">
              <li>
                今回は、こちらのAPIを使用しておりますので詳細を知りたい方は以下をご覧ください。<br />
                <a href="https://zipaddress.net/">電話番号-住所検索API</a>
              </li>
              <li className="mt-2">
                こちらの記事にReact, Reduxを使用した方法もありますので、参考にしたい方は以下をご覧ください。<br />
                <a href="https://qiita.com/oda3104/items/317a1309183d50061a6e?utm_campaign=popular_items&utm_medium=feed&utm_source=popular_items">React、Reduxとaxiosを使って郵便番号から住所を取得する</a>
              </li>
            </ul>
          )
        }

        {
          ReportCommonLayout(
            "住所自動入力の全ソースコード",
            <div className="mt-3">
              {
                CodeLayout(
                  `
  import { useState } from "react";
  import axios from "axios";

  export const AddressForm = () => {
    const [prefecture, setPrefecture] = useState("");
    const [city, setCity] = useState("");
    const [town, setTown] = useState("");

    const handleChange = async (e) => {
      const res = await axios.get('https://api.zipaddress.net/?zipcode=' + e.target.value);
      if(res.data.code === 200) {
        setPrefecture(res.data.data.pref);
        setCity(res.data.data.city);
        setTown(res.data.data.town);
      }
    }

    return (
      <div className="">
        <div className="row justify-content-center mt-5">
          <div className="col-md-4 col-4">
            <label htmlFor="zipCode" className="form-label">郵便番号</label>
            <input type="text" className="form-control" onChange={handleChange} />
          </div>
        </div>
        <div className="row justify-content-center mt-5">
          <div className="col-md-4 col-4">
            <label htmlFor="prefecture" className="form-label">都道府県</label>
            <input type="text" className="form-control" value={prefecture} disabled={true} />
          </div>
        </div>
        <div className="row justify-content-center mt-5">
          <div className="col-md-4 col-4">
            <label htmlFor="city" className="form-label">市町村</label>
            <input type="text" className="form-control" value={city} disabled={true} />
          </div>
        </div>
        <div className="row justify-content-center mt-5">
          <div className="col-md-4 col-4">
            <label htmlFor="town" className="form-label">町名</label>
            <input type="text" className="form-control" value={town} disabled={true} />
          </div>
        </div>
      </div>
    )
  }

  export default AddressForm`
                )
              }
            </div>
          )
        }
      </div>
    </CommonLayout>
  )
}

export default AddressFormReport2;