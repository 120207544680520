import { useEffect, useState, useRef } from 'react';
import CommonLayout from '../layouts/CommonLayout';

export const TodoList = () => {
  const [todoList, setTodoList] = useState([]);
  const item = useRef("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    let list = JSON.parse(localStorage.getItem("okapy-blog_todoList"));
    if (list) {
      setTodoList(list);
    }
  }, []);

  const handleClickAddition = () => {
    let list = JSON.parse(localStorage.getItem("okapy-blog_todoList"));
    if (list) {
      if (list.length >= 10) {
        return setMessage("10件までしか登録できません");
      }
      setMessage("");
      list.push({ item: item.current.value, option: true });
    } else {
      list = [{ item: item.current.value, option: true }];
    }
    item.current.value = "";

    localStorage.setItem("okapy-blog_todoList", JSON.stringify(list));
    setTodoList(list);
  }

  const handleClickEdit = (index) => {
    let list = JSON.parse(localStorage.getItem("okapy-blog_todoList"));
    list[index].option = false;

    localStorage.setItem("okapy-blog_todoList", JSON.stringify(list));
    setTodoList(list);
  }

  const handleClickUpdate = (index) => {
    let list = JSON.parse(localStorage.getItem("okapy-blog_todoList"));
    list[index].option = true;

    localStorage.setItem("okapy-blog_todoList", JSON.stringify(list));
    setTodoList(list);
  }

  const handleClickDelete = (index) => {
    let list = JSON.parse(localStorage.getItem("okapy-blog_todoList"));
    list.splice(index, 1);

    localStorage.setItem("okapy-blog_todoList", JSON.stringify(list));
    setTodoList(list);
  }

  const handleChangeListItem = (e, index) => {
    let list = JSON.parse(localStorage.getItem("okapy-blog_todoList"));
    list[index].item = e.target.value;

    localStorage.setItem("okapy-blog_todoList", JSON.stringify(list));
    setTodoList(list);
  }

  return (
    <CommonLayout>
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-12">
            <h3 className="font-weight-light mt-4 text-black">Todoリスト</h3>
            <div className="row justify-content-center text-center text-danger">
              こちらに入力した内容が漏洩しても当ブログで責任は取れませんので<br/>
              個人情報等の情報は入力しないでください
            </div>
          </div>
        </div>
        <div className="row justify-content-center text-center mt-3">
          <div className="col-6">
            <input type="text" name="item" ref={item} className="form-control" />
          </div>
          <div className="col-2">
            <button type="button" className="btn btn-info" onClick={handleClickAddition}>追加</button>
          </div>
        </div>
        <div className="row justify-content-center text-center text-danger">
          {message}
        </div>
        {
          todoList.map((row, index) => {
            return (
              <div className="row justify-content-center text-center mt-2" key={index}>
                <div className="col-8">
                  <input type="text" value={row.item} className="form-control" onChange={e => handleChangeListItem(e, index)} disabled={row.option} />
                </div>
                {
                  row.option ?
                    <div className="col-2">
                      <button type="button" className="btn btn-secondary" onClick={() => { handleClickEdit(index) }}>編集</button>
                    </div>
                    :
                    <div className="col-2">
                      <button type="button" className="btn btn-warning" onClick={() => { handleClickUpdate(index) }}>更新</button>
                    </div>
                }

                <div className="col-2">
                  <button type="button" className="btn btn-danger" onClick={() => handleClickDelete(index)}>削除</button>
                </div>
              </div>
            )
          })
        }
      </div>
    </CommonLayout>
  )
}

export default TodoList;