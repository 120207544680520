import CommonLayout from '../../layouts/CommonLayout';
import ReportCommonLayout from '../../../components/reportLayout/ReportCommonLayout';
import CodeLayout from '../../../components/CodeLayout';
import React, { useState } from 'react';

export const Tutorial3 = () => {
  return (
    <CommonLayout>
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-12">
            <h3 className="font-weight-light mt-4 text-black">React チュートリアル③<br />～useState編～</h3>
          </div>
        </div>

        {
          ReportCommonLayout(
            "以下の方向け",
            <ul className="mt-3">
              <li><a href='/reports/react/tutorial/1'>React チュートリアル②</a>のuseStateについて詳しく知りたい方</li>
            </ul>
          )
        }

        {
          ReportCommonLayout(
            "参考サイト",
            <ul className="mt-3">
              <li>
                <a href="https://ja.reactjs.org/docs/hooks-state.html">
                  ステートフック(state hook)の利用法
                </a>
              </li>
            </ul>
          )
        }

        {
          ReportCommonLayout(
            "今回の流れ",
            <div className="mt-3">
              <ul>
                <li>React Hookの説明</li>
                <li>クラス型での使い方</li>
                <li>関数型での使い方</li>
                <li>クラス型と関数型の違い</li>
              </ul>
            </div>
          )
        }

        {
          ReportCommonLayout(
            "React Hookの説明",
            <div className="mt-3">
              <p>
                React HookとはReactの機能に接続するための関数です。<br />
                今回紹介するuseStateは、Reactのstateの機能を関数型で使用できるような機能となっています。<br />
                また、今回紹介するuseStateのようなフックは、関数型でReact機能を使用できるように追加された機能
                であるためクラス内部では動作しない仕様となっています。
              </p>
            </div>
          )
        }

        {
          ReportCommonLayout(
            "クラス型での使い方",
            <div className="mt-3">
              <p>
                はじめに、関数型を紹介する前にここではクラス型での使い方を紹介します。<br />
                私自身普段はクラス型で書くことがないため、<a href="https://ja.reactjs.org/docs/hooks-state.html">Reactの公式サイト</a>を参考にしました。<br />
                以下のコードがクラス型での使い方になります。コードの下に実際の機能もありますのでご参考にして下さい。
              </p>
              {
                CodeLayout(
                  `
  import React from 'react';
  
  class ClassExample extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        count: 0
      };
    }
                  
    render() {
      return (
        <p>
          count : {this.state.count}
          {'　'}
          <button onClick={() => this.setState({ count: this.state.count + 1 })}>カウント</button>
        </p>
      )
    }
  }
`
                )
              }
              <div className="row justify-content-center text-center">
                <div className="col-12">
                  <ClassExample />
                </div>
              </div>
            </div>
          )
        }



        {
          ReportCommonLayout(
            "関数型での使い方",
            <div className="mt-3">
              <p>
                ここでは、関数型での使い方を紹介します。<br />
                クラス型での使い方を関数型に変更すると以下のようになります。<br />
                クラス型と同様にコードの下に実際の機能もありますのでご参考にして下さい。
              </p>
              {
                CodeLayout(
                  `
  import { useState } from 'react';

  const FunctionExample = () => {
    const [count, setCount] = useState(0);
                  
    return (
      <p>
        count : {count}
        {'　'}
        <button onClick={() => setCount(count + 1)}>カウント</button>
      </p>
    )
  }
`
                )
              }
              <div className="row justify-content-center text-center">
                <div className="col-12">
                  <FunctionExample />
                </div>
              </div>
              <p>
                関数型を使用する手順は次の通りです。
                <ol>
                  <li>
                    useStateフックの導入<br />
                    useStateはreactパッケージの中に入っているため、
                    {
                      CodeLayout(
                        `
  import { useState } from 'react';
`)}
                    を追加
                  </li>
                  <li>
                    変数を定義<br />
                    useStateフックを使用して変数を定義し、定義する時に初期値を代入(初期値はなくても問題ないが入れることを推奨)<br />
                    {
                      CodeLayout(
                        `
  const [count, setCount] = useState(0);
`)}
                  </li>
                  <li>
                    値の更新<br />
                    2で定義をしたsetCountを使用し、値の更新をする<br />
                    {
                      CodeLayout(
                        `
  setCount(count + 1)
`)}
                  </li>
                </ol>
              </p>
            </div>
          )
        }

        {
          ReportCommonLayout(
            "クラス型と関数型の違い",
            <div className="mt-3">
              <p>
                当初は紹介するつもりはなかったのですが今回の記事を作成するにあたって、記述方法以外の違いを発見したので共有しておきます。<br/>
                ファイルの更新を行った際にクラス型ではcountの値は更新されるのに対して、関数型では更新されない点が今回発見した内容です。<br/>
                「この違いが何の影響があるの？」と思う方がいらっしゃるかと思いますが、もし本番環境を更新した場合にクラス型の
                場合はユーザーのページも更新されstate変数が更新されます。<br/>
                そのため、ユーザーに影響を与えない点においては、今回紹介したuseStateを使用した方法で記述する方がおすすめです。<br/>
                ちなみにですが、ページをリロードした場合は、どちらも値の更新がされます(useStateの初期値)。
              </p>
            </div>
          )
        }

        {
          ReportCommonLayout(
            "最後に",
            <p>
              「React チュートリアル③ ～useState編～」は以上となります。<br />
              私自身もuseStateについて理解できていない部分も多いため今後も更新していきたいと思います。<br />
              今回記事を読んで頂いた方の参考になれば嬉しいです。<br />
              また、今回の内容でわからない点や今後取り上げてほしい内容等あれば、お問い合わせからお願いします。
            </p>
          )
        }
      </div>
    </CommonLayout>
  )
}

class ClassExample extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0
    };
  }

  render() {
    return (
      <p>
        count : {this.state.count}
        {'　'}
        <button onClick={() => this.setState({ count: this.state.count + 1 })}>カウント</button>
      </p>
    )
  }
}

const FunctionExample = () => {
  const [count, setCount] = useState(0);

  return (
    <p>
      count : {count}
      {'　'}
      <button onClick={() => setCount(count + 1)}>カウント</button>
    </p>
  )
}

export default Tutorial3;