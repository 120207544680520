import CommonLayout from '../layouts/CommonLayout';
import { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';


import * as Constant from "../../constant";

export const AllReport = () => {
  const [reportList, setReportList] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const result = await getReportList();
      setReportList(result.reportList);
    }

    fetch()
  }, []);

  return (
    <CommonLayout>
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-md-7">
            <h1 className="font-weight-light mt-4 text-black">記事一覧</h1>
          </div>
        </div>
        <div className="card mt-4 text-black">
          <div className="card-body">
            <div className="card-header">
              <div className="row justify-content-center text-center">
                <div className="col-3 col-lg-2">
                  <h3>日付</h3>
                </div>
                <div className="col-3 col-lg-3">
                  <h3>分類</h3>
                </div>
                <div className="col-6 col-lg-7">
                  <h3>タイトル</h3>
                </div>
              </div>
            </div>
            {
              reportList.map((row, index) => {
                return (
                  <div className="row justify-content-center text-center mt-1" key={index}>
                    <div className="col-3 col-lg-2">
                      {moment(new Date(row.Date).toISOString()).format("YYYY年M月D日")}
                    </div>
                    <div className="col-3">
                      {row.Type.map((row, typeIndex) => {
                        return typeIndex === 0
                          ?
                          TypeCodeToName(row) :
                          ", " + TypeCodeToName(row);
                      })}
                    </div>
                    <div className="col-6 col-lg-7">
                      <a href={`/reports/${row.Path}`}>{row.Title}</a>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </CommonLayout>
  )
}

const TypeCodeToName = (code) => {
  switch (code) {
    case 1:
      return "React";
    case 2:
      return "JavaScript"
    default:
      return "";
  }
}

const getReportList = async (keyword) => {
  const options = {
    keyword: "aaa"
  };

  return await axios.get(`${Constant.LambdaApiGateWay}/reports`, { params: options })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      console.log(err);
    });
}

export default AllReport;