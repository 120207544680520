import CommonLayout from '../../layouts/CommonLayout';
import ReportCommonLayout from '../../../components/reportLayout/ReportCommonLayout';
import CodeLayout from '../../../components/CodeLayout';

export const TicTacToeGameReport3 = () => {
  return (
    <CommonLayout>
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-12">
            <h3 className="font-weight-light mt-4 text-black">
              Reactでの○×ゲームの作成③<br />
              全ソースコード
            </h3>
          </div>
        </div>

        {
          ReportCommonLayout(
            "以下の方向け",
            <ul className="mt-3">
              以下のサイトで解説しておりますので、解説付きを読みたい方は以下のサイトをご覧ください。
              <li>
                <a href="/reports/tic_tac_toe_game/1">Reactでの○×ゲームの作成①</a>
              </li>
              <li>
                <a href="/reports/tic_tac_toe_game/2">Reactでの○×ゲームの作成②</a>
              </li>
            </ul>
          )
        }

        {
          ReportCommonLayout(
            "参考サイト",
            <ul className="mt-3">
              クラス型で記述をしたい方は以下のサイトにサンプルがあります。
              <li>
                <a href="https://ja.reactjs.org/tutorial/tutorial.html">
                  チュートリアル：React の導入 – React
                </a>
              </li>
            </ul>
          )
        }

        {
          ReportCommonLayout(
            "○×ゲームの全ソースコード",
            <div className="mt-3">
              {
                CodeLayout(
                  `
  import { useState } from 'react';

  export const TicTacToeGame = () => {
    const [board, setBoard] = useState(Array(3 ** 2).fill(null));
    const [nextPlayer, setNextPlayer] = useState("○");
    const [winnerPlayer, setWinnerPlayer] = useState(null);

    const handleClick = (i) => {
      let tempBoard = board;
      tempBoard[i] = nextPlayer;
      if(CalculateWinner(tempBoard)) {
        setWinnerPlayer(nextPlayer);
      } else {
        nextPlayer === "○" ? setNextPlayer("×") : setNextPlayer("○");
      }
      setBoard(tempBoard);
    }

    const handleClickReset = () => {
      setBoard(Array(3 ** 2).fill(null));
      setNextPlayer("○");
      setWinnerPlayer(null);
    }

    return (
      <div className="">
        {
          winnerPlayer ? 
            \`プレイヤー\${winnerPlayer}の勝利です\`:
            \`次のプレイヤーは\${nextPlayer}です\`
        }
        {
          Array(3).fill(0).map((row, rowIndex) => {
            return (
              <div className="row" key={rowIndex}>
                {
                  Array(3).fill(0).map((col, colIndex) => {
                    return (
                      <button className="square"
                        key={rowIndex * 3 + colIndex}
                        onClick={()=>{handleClick(rowIndex * 3 + colIndex)}}
                        disabled={board[rowIndex * 3 + colIndex] || winnerPlayer}
                      >
                        {board[rowIndex * 3 + colIndex]}
                      </button>
                    )
                  })
                }
              </div>
            )
          })
        }
        <button onClick={handleClickReset}>Reset</button>
      </div>
    )
  }

  const CalculateWinner = (squares) => {
    const lines = [
      [0, 1, 2],
      [3, 4, 5],
      [6, 7, 8],
      [0, 3, 6],
      [1, 4, 7],
      [2, 5, 8],
      [0, 4, 8],
      [2, 4, 6],
    ];
    for (let i = 0; i < lines.length; i++) {
      const [a, b, c] = lines[i];
      if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
        return true;
      }
    }
    return false;
  }

  export default TicTacToeGame;
`
                )
              }
            </div>
          )
        }

      </div>
    </CommonLayout>
  )
}

export default TicTacToeGameReport3;