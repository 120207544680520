import React from 'react';
import CommonLayout from '../layouts/CommonLayout';

export const About = () => {
  return (
    <CommonLayout>
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-md-7">
            <h1 className="font-weight-light mt-4 text-black">当ブログの紹介</h1>
          </div>
        </div>

        <div className="row justify-content-center mt-5">
          <div className="col-10">
            <h2><span className="badge rounded-pill">当ブログの目的</span></h2>
            <div className="col-13 mt-3">
              <div className="card">
                <div className="card-body">
                  当ブログは、これからIT知識や技術を学びたいと考えている方向けに情報を発信するブログとなります。<br />
                  運営している私自身も膨大なIT知識の一部しか理解していないと気づき共に学び成長していきたいと
                  考えていますので、これからIT関連の趣味を持ちたいという方や職種につきたいと思っている方は一緒に頑張りましょう！<br />
                  また、紹介してほしい技術や知識等がありましたら、気軽に<a href="/contact">お問い合わせ</a>にご記入下さい。
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mt-5">
          <div className="col-10">
            <h2><span className="badge rounded-pill">当ブログの環境・利用技術</span></h2>
            <div className="col-13 mt-3">
              <div className="card">
                <div className="card-body">
                  <strong>当ブログの環境</strong>
                  <ul>
                    <li>フロントエンド {'>>>'} <a href="https://ja.reactjs.org/">React</a></li>
                    <li>バックエンド {'>>>'} <a href="https://aws.amazon.com/jp/lambda/">AWS Lambda</a></li>
                    <li>ドメイン, DNS管理 {'>>>'} <a href="https://aws.amazon.com/jp/route53/">AWS Route53</a></li>
                    <li>データベース {'>>>'} <a href="https://aws.amazon.com/jp/dynamodb/">AWS DynamoDB</a></li>
                  </ul>
                  各技術をクリックすることで日本語訳の公式サイトを閲覧することができます。<br />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mt-5">
          <div className="col-10">
            <h2><span className="badge rounded-pill">運営者情報</span></h2>
            <div className="col-13 mt-3">
              <div className="card">
                <div className="card-body">
                  <strong>経歴</strong><br />
                  高校を卒業後、4年制大学の情報系学部に進学し情報工学を学ぶ<br />
                  情報工学ではC言語やJava等のプログラミングやネットワーク、データベース、セキュリティ、画像処理等の基礎を学ぶ<br />
                  大学卒業後、ITエンジニアとして就職<br />
                  <strong>これまでの実務実績</strong>
                  <ul>
                    <li>フレームワークのLaravelを使用したBtoB向けWebサービスの開発、テストを約1年担当</li>
                    <li>
                      AWSのLambda, DynamoDB, CognitoとフロントエンドとしてReactを使用しBtoC向け<br />
                      Webサービスの開発兼SEを約半年担当
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CommonLayout>
  );
}

export default About;