import CommonLayout from '../../layouts/CommonLayout';
import ReportCommonLayout from '../../../components/reportLayout/ReportCommonLayout';
import CodeLayout from '../../../components/CodeLayout';

export const TicTacToeGameReport2 = () => {
  return (
    <CommonLayout>
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-10">
            <h3 className="font-weight-light mt-4 text-black">Reactでの○×ゲームの作成②</h3>
          </div>
        </div>

        {
          ReportCommonLayout(
            "以下の方向け",
            <ul className="mt-3">
              <li>以下の記事の続きとなっています。まだご覧になってない方は先に以下の記事を読みことをおススメします！</li>
              <a href="/reports/tic_tac_toe_game/1">Reactでの○×ゲームの作成①</a>
            </ul>
          )
        }

        {
          ReportCommonLayout(
            "参考サイト",
            <ul className="mt-3">
              <li>
                <a href="https://ja.reactjs.org/tutorial/tutorial.html">
                  チュートリアル：React の導入 – React
                </a>
              </li>
            </ul>
          )
        }

        {
          ReportCommonLayout(
            "作成の流れ",
            <ul className="mt-3">
              <li>ボードの作成</li>
              <li>ボタンの有効化　　　　前回↑</li>
              -------------------------------------------
              <li>プレイヤーの追加　　　今回↓</li>
              <li>ゲームの勝敗の判定</li>
            </ul>
          )
        }

        {
          ReportCommonLayout(
            "プレイヤーの追加",
            <div className="mt-3">
              <p>
                ここでは、プレイヤーの追加を行っていきます。<br />
                次のプレイヤーを把握するために"nextPlayer"変数を定義して使用していきます。<br />
                nextPlayer変数には初期値として"○"を入れておきます。
                また、board変数と同様に今後setStateによって変数を管理するため"setNextPlayer"も定義しておきます
              </p>
              {
                CodeLayout(
                  `
  const [nextPlayer, setNextPlayer] = useState("○");
`
                )
              }
              <p>
                次に、handleClick関数を変更します。<br />
                ボードに入れる文字をnextPlayserの文字にして、その後nextPlayerを今の状態と反対にします。<br />
                これにより、交互に入力することができます。
              </p>
              {
                CodeLayout(
                  `
  const handleClick = (i) => {
    let tempBoard = board;
    tempBoard[i] = nextPlayer;
    nextPlayer === "○" ? setNextPlayer("×") : setNextPlayer("○");
    setBoard(tempBoard);
  }
`
                )
              }
              <p>
                また、前回発生していた不具合(画面に正常に表示されない)がこの変更(上記ソースコードの4行目)により、私の画面では正常に表示されるようになりました。<br />
                正直なところ正確な原因が分かっていないため、今後解決できたら更新したいと思います。
              </p>
              <p>
                上記のコードのままでも正常に操作はできますが、<br />次に操作するプレイヤーの状態が画面に表示されていないため、盤面の上に表示したいと思います。
              </p>
              {
                CodeLayout(
                  `
  {\`次のプレイヤーは\${nextPlayer}です\`}
`
                )
              }
            </div>
          )
        }

        {
          ReportCommonLayout(
            "ゲームの勝敗の判定",
            <div className="mt-3">
              <p>
                ここでは、ゲームの勝敗を判別する処理を作成していきます。<br />
                勝敗を判断する処理は、公式サイトのチュートリアルにありますのでこちらを使用して作成していきます。
                以下のように変更して、TicTacToeGame関数の下におきます。
              </p>
              {
                CodeLayout(
                  `
  export const TicTacToeGame = () => {

  }

  const CalculateWinner = (squares) => {
    const lines = [
      [0, 1, 2],
      [3, 4, 5],
      [6, 7, 8],
      [0, 3, 6],
      [1, 4, 7],
      [2, 5, 8],
      [0, 4, 8],
      [2, 4, 6],
    ];
    for (let i = 0; i < lines.length; i++) {
      const [a, b, c] = lines[i];
      if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
        return true;
      }
    }
    return false;
  }

  export default TicTacToeGame;
`
                )
              }
              <p>
                プレイヤーがボードに入力した時に、勝敗を判断する必要があるためhandleClick関数の<br />
                tempBoardに値を入れた後に判別をします。<br />
                CalculateWinnerからtrueが返れば勝者が決まり、falseであればそのまま続ける処理にします。<br />
                また、勝者が決まった際に状態を把握する変数がないため、こちらもこの時に作成します。
              </p>
              {
                CodeLayout(
                  `
  const [winnerPlayer, setWinnerPlayer] = useState(null);
`
                )
              }
              {
                CodeLayout(
                  `
  const handleClick = (i) => {
    let tempBoard = board;
    tempBoard[i] = nextPlayer;
    if(CalculateWinner(tempBoard)) {
      setWinnerPlayer(nextPlayer);
    } else {
      nextPlayer === "○" ? setNextPlayer("×") : setNextPlayer("○");
    }
    setBoard(tempBoard);
  }
`
                )
              }
              <p>
                次は、勝者が決まった時にボードの上の表示を変更する処理を追加していきます。<br />
                また、一度ボタンに入力された時と勝敗が決まった時にボタンに入力できないように変更していきます。
              </p>
              {
                CodeLayout(
                  `
  {
    winnerPlayer ? 
      \`プレイヤー\${winnerPlayer}の勝利です\`:
      \`次のプレイヤーは\${nextPlayer}です\`
  }
`
                )
              }
              {
                CodeLayout(
                  `
  <button className="square"
    key={rowIndex * 3 + colIndex}
    onClick={()=>{handleClick(rowIndex * 3 + colIndex)}}
    disabled={board[rowIndex * 3 + colIndex] || winnerPlayer}
  >
    {board[rowIndex * 3 + colIndex]}
  </button>
`
                )
              }
              <p>
                最後に勝敗がつかなかった時や勝敗がついた時にボードをリセットするボタンを作成しましょう！<br />
                新たにhandleClickReset関数をTicTocToeGame関数の中に定義して処理を追加していきます。
              </p>
              {
                CodeLayout(
                  `
  <button onClick={handleClickReset}>Reset</button>
`
                )
              }
              {
                CodeLayout(
                  `
  const handleClickReset = () => {
    setBoard(Array(3 ** 2).fill(null));
    setNextPlayer("○");
    setWinnerPlayer(null);
  }
`
                )
              }

            </div>
          )
        }
        {
          ReportCommonLayout(
            "最後に",
            <p>
              これで○×ゲームの完成になります。<br />
              <a href="/reports/tic_tac_toe_game/3">Reactで○×ゲームの作成③</a>に○×ゲームの全体のコードを記載しておきますので参考にしてください。<br />
              また、わからない点や試してほしいこと等あれば、お問い合わせから入力して頂ければできる限り回答します！<br />
            </p>
          )
        }
      </div>
    </CommonLayout>
  )
}

export default TicTacToeGameReport2;