const CodeLayout = (code, spaceSize) => {
  return (
    <div className="row justify-content-center mb-3">
      <div className="col-13">
        <div className="card text-white bg-secondary">
          <pre>{code}</pre>
        </div>
      </div>
    </div>
  )
}

export default CodeLayout;