import React, { useState, useEffect } from 'react';
import CommonLayout from '../layouts/CommonLayout';

export const Report = () => {
  const [tutorialReport, setTutorialReport] = useState([]);
  const [knowledgeReport, setKnowledgeReport] = useState([]);
  const [errorResolutionReport, setErrorResolutionReport] = useState([]);

  useEffect(() => {
    setTutorialReport([{
      Title: "React チュートリアル① ～環境構築編～",
      Path: "react/tutorial/1"
    }]);

    setKnowledgeReport([{
      Title: "axiosの導入＆使い方",
      Path: "knowledge/axios"
    }, {
      Title: "local storageの使い方",
      Path: "knowledge/local_storage"
    }]);

    setErrorResolutionReport([{
      Title: "iPhoneでサイトが表示されない不具合の解決",
      Path: "error_resolution/1"
    }])
  }, []);

  return (
    <CommonLayout>
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-md-7">
            <h1 className="font-weight-light mt-4 text-black">各記事紹介</h1>
          </div>
        </div>

        <div className="row justify-content-center mt-5">
          <div className="col-10">
            <h2><span className="badge rounded-pill">初めての方はこちら</span></h2>
            <div className="col-13 mt-3">
              <div className="card">
                <div className="card-body">
                  {
                    tutorialReport.map((row, index) => {
                      return (
                        <ul className="row mt-2" key={index}>
                          <li><a href={"/reports/" + row.Path}>{row.Title}</a></li>
                        </ul>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mt-5">
          <div className="col-10">
            <h2><span className="badge rounded-pill">技術記事はこちら</span></h2>
            <div className="col-13 mt-3">
              <div className="card">
                <div className="card-body">
                  {
                    knowledgeReport.map((row, index) => {
                      return (
                        <ul className="row mt-2" key={index}>
                          <li><a href={"/reports/" + row.Path}>{row.Title}</a></li>
                        </ul>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mt-5">
          <div className="col-10">
            <h2><span className="badge rounded-pill">エラー解決記事はこちら</span></h2>
            <div className="col-13 mt-3">
              <div className="card">
                <div className="card-body">
                  {
                    errorResolutionReport.map((row, index) => {
                      return (
                        <ul className="row mt-2" key={index}>
                          <li><a href={"/reports/" + row.Path}>{row.Title}</a></li>
                        </ul>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CommonLayout>
  );
}

export default Report;