import Header from './Header';
import Footer from './Footer';

export const CommonLayout = (props) => {
  return (
    <div>
      <Header />
      <div className="text-body">
        <section>
          {props.children}
        </section>
      </div>
      <Footer />
    </div>
  )
}

export default CommonLayout;