import CommonLayout from '../../layouts/CommonLayout';
import ReportCommonLayout from '../../../components/reportLayout/ReportCommonLayout';
import CodeLayout from '../../../components/CodeLayout';

export const ErrorResolution1 = () => {
  return (
    <CommonLayout>
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-12">
            <h3 className="font-weight-light mt-4 text-black">iPhoneでサイトが表示されない不具合の解決</h3>
          </div>
        </div>

        {
          ReportCommonLayout(
            "はじめに",
            <div className="mt-3">
              <p>
                今回はサイトを作成し、Web上に公開した際に発生した(発見した)エラーの解決となっています。<br />
                ローカル環境でも本番環境でも正常に表示されているし、コンソールにも警告やエラーメッセージがないため
                「なぜ！？」と疑問に思っていたときに調査した内容になっています。<br />
                同じような問題がある方は、ぜひ参考にしてください！
              </p>
            </div>
          )
        }

        {
          ReportCommonLayout(
            "以下の方向け",
            <ul className="mt-3">
              <li>iPhoneでサイトが表示されない方</li>
              <li>Reactでサイトを作成している方</li>
              <li>JavaScriptを使用している方</li>
            </ul>
          )
        }

        {
          ReportCommonLayout(
            "参考サイト",
            <ul className="mt-3">
              <li>
                <a href="https://qiita.com/pearmaster8293/items/b5b0df28147eb049f1ea">new Date()を出力したらSafariでNaNになる件</a>
              </li>
            </ul>
          )
        }

        {
          ReportCommonLayout(
            "iPhoneで画面が表示されない・表記が変わる理由",
            <div className="mt-3">
              <p>
                はじめに表記が変わる理由は「safariでは、new Date()で-(ハイフン)があると"Invalid Date"」となるためでした。<br />
                <br />
                私のサイトでは、データベースに"YYYY-MM-DD"という形で登録していました。<br />
                この形のまま情報を取得し、年月日に表示を変える際にエラーが発生していたということです。<br />
                <br />
                また、iPhoneで画面が表示されない理由は「上記のエラーで"Invalid Date"となっている値をmoment()
                で表記を変えようとしている」からではないかと考えています。<br />
                そのため、moment()を削除すると"Invalid Date"が表示されるようになります。
              </p>
            </div>
          )
        }

        {
          ReportCommonLayout(
            "解決方法",
            <div className="mt-3">
              <p>
                上記の原因からnew Date()の中に入る値を変更することで解決することができます。<br />
                私の場合は、データベースに保存する形を変更することで解決をしました。
                {
                  CodeLayout(
                    `
  #before
  2022-1-1
  #after
  2022/1/1
`
                  )
                }
              </p>
            </div>
          )
        }

        {
          ReportCommonLayout(
            "最後に",
            <div className="mt-3">
              <p>
                今回は、ブログを公開した際に発生したエラーについてまとめました。<br />
                参考になれば嬉しいです!<br />
                また、今後取り上げてほしい内容や疑問等があれば、お問い合わせからお願いします！
              </p>
            </div>
          )
        }
      </div>
    </CommonLayout>
  )
}

export default ErrorResolution1;