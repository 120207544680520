import React, { useEffect, useState } from 'react';
import CommonLayout from '../layouts/CommonLayout';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { init, send } from 'emailjs-com';

export const Contact = () => {
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(false);

  useEffect(() => {
    const time = localStorage.getItem('okapy-blog_contact');
    localStorage.removeItem('okapy-blog_contact')
    if(time) {
      const now = new Date().getFullYear() + "-" +new Date().getMonth() +"-"+ new Date().getDate();
      if(time === now) {
        setStatus(true);
        setMessage(`本日はお問い合わせを受け付けることができません。 \n 別日にお問い合わせをお願いします。`)
      }
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      contactType: '',
      contactDetail: '',
      isSendEmail: false
    },
    validationSchema: Yup.object({
      name: Yup.string().required('名前は必須です'),
      email: Yup.string().email('メールアドレスが有効でありません').required('メールアドレスは必須です'),
      contactType: Yup.string().required('お問い合わせ内容を選んでください').matches(/^[1-3]/, { message: "お問い合わせ内容を選んでください" }),
      contactDetail: Yup.string().required('詳細は必須です')
    }),
    onSubmit: async (values) => {
      if (formik.isValid) {
        if (values.isSendEmail) {
          sendEmail(values);
        }
        sendEmail(values, "okapy1024@gmail.com")
      }
    },
    validateOnChange: false,
    validateOnBlur: false
  });

  const sendEmail = async (values, email) => {
    // emailjsのUser_IDを使って初期化
    init(process.env.REACT_APP_EMAILJS_USER_ID);
    console.log(values)

    // 環境変数からService_IDとTemplate_IDを取得する。
    const emailjsServiceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const emailjsTemplateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;

    // emailjsのテンプレートに渡すパラメータを宣言
    const templateParams = {
      email: email? email: values.email,
      name: values.name,
      type: await ContactTypeToName(values.contactType),
      message: values.contactDetail,
    };

    // ServiceId,Template_ID,テンプレートに渡すパラメータを引数にemailjsを呼び出し
    send(emailjsServiceId, emailjsTemplateId, templateParams)
      .then(() => {
        const now = new Date().getFullYear() + "-" +new Date().getMonth() +"-"+ new Date().getDate();
        localStorage.setItem('okapy-blog_contact', now);
        setMessage("送信が完了しました。");
      });
  }

  return (
    <CommonLayout>
      <div className="container">
        <form onSubmit={formik.handleSubmit}>
          <div className="row justify-content-center text-center">
            <div className="col-md-7">
              <h1 className="font-weight-light mt-4 text-black">お問い合わせ</h1>
            </div>
          </div>
          <div className="row justify-content-center mt-5">
            <div className="col-md-7 col-7">
              <label className="h5"><strong>お名前</strong></label>
              <input type="text" name="name" className="form-control form-control-lg" placeholder="お名前" {...formik.getFieldProps('name')} />
              {formik.errors.name ? <div className="error-message">{"*" + formik.errors.name}</div> : null}
            </div>
          </div>
          <div className="row justify-content-center mt-4">
            <div className="col-md-7 col-7">
              <label className="h5"><strong>メールアドレス</strong></label>
              <input type="text" name="email" className="form-control form-control-lg" placeholder="email@example.com" {...formik.getFieldProps('email')} />
              {formik.errors.email ? <div className="error-message">{"*" + formik.errors.email}</div> : null}
            </div>
          </div>
          <div className="row justify-content-center mt-4">
            <div className="col-md-7 col-7">
              <label className="h5"><strong>お問い合わせ内容</strong></label>
              <select className="form-select form-control-lg" name="contactType" {...formik.getFieldProps('contactType')} >
                <option defaultChecked value="0">お問い合わせ内容を選んでください</option>
                <option value="1">疑問・質問</option>
                <option value="2">ブログの問題点の指摘</option>
                <option value="3">その他</option>
              </select>
              {formik.errors.contactType ? <div className="error-message">{"*" + formik.errors.contactType}</div> : null}
            </div>
          </div>
          <div className="row justify-content-center mt-4">
            <div className="col-md-7 col-7">
              <label className="h5"><strong>詳細</strong></label>
              <textarea className="form-control" rows="3" name="contactDetail" {...formik.getFieldProps('contactDetail')} />
              {formik.errors.contactDetail ? <div className="error-message">{"*" + formik.errors.contactDetail}</div> : null}
            </div>
          </div>
          <div className="row justify-content-center mt-4">
            <div className="col-md-7 col-7">
              <input className="form-check-input" type="checkbox" name="isSendEmail" {...formik.getFieldProps('isSendEmail')} />
              <label className="form-check-label ms-3 h5" htmlFor="flexCheckDefault"><strong>確認メールを受け取る</strong></label>
            </div>
          </div>
          <div className="row justify-content-center mt-4">
            <div className="col-md-7 col-7 text-end">
              <button type="submit" className="btn btn-primary" disabled={status}>送信</button>
            </div>
            <div className="text-center text-danger">
              {message.split('\n').map((row, index) => {
                return <div key={index}>{row} <br/></div>;
              })}
            </div>
          </div>
        </form>
      </div>
    </CommonLayout>
  );
}

const ContactTypeToName = async (code) => {
  switch(code) {
    case "1":
      return "疑問・質問";
    case "2": 
      return "ブログの問題点の指摘";
    case "3":
      return "その他";
    default:
      return "";
  }
}

export default Contact;