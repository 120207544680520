import CommonLayout from '../../layouts/CommonLayout';
import ReportCommonLayout from '../../../components/reportLayout/ReportCommonLayout';
import CodeLayout from '../../../components/CodeLayout';
import photo1 from '../../../img/react_tutorial1.PNG';

export const Tutorial1 = () => {
  return (
    <CommonLayout>
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-12">
            <h3 className="font-weight-light mt-4 text-black">React チュートリアル①<br />～環境構築編～</h3>
          </div>
        </div>

        {
          ReportCommonLayout(
            "以下の方向け",
            <ul className="mt-3">
              <li>React開発の環境構築をしたい方</li>
            </ul>
          )
        }

        {
          ReportCommonLayout(
            "参考サイト",
            <ul className="mt-3">
              <li>
                <a href="https://ja.reactjs.org/tutorial/tutorial.html">
                  チュートリアル：React の導入 – React
                </a>
              </li>
            </ul>
          )
        }

        {
          ReportCommonLayout(
            "今回の流れ",
            <ul className="mt-3">
              <li>Node.jsのインストール</li>
              <li>React Appの作成</li>
            </ul>
          )
        }

        {
          ReportCommonLayout(
            "Node.jsのインストール",
            <div className="mt-3">
              <p>
                ここでは、Node.jsのインストールを行っていきます。<br />
                <a href="https://nodejs.org/ja/">Node.jsのサイト</a>にアクセスをして、左側のLTSのダウンロードしてください。<br />
                LTSとは、Long Term Supportの略で長期サポートされているバージョンとなっています。<br />
                右側の最新版は最新版ではあるけれど安定性を約束しないバージョンとなっています。<br />
                詳細は以下の記事参照<br />
                <a href="https://www.tweeeety.blog/entry/2018/05/22/194312">【Node.js】Node.jsのLTSやCURRENTってなんだ？</a>
              </p>
            </div>
          )
        }

        {
          ReportCommonLayout(
            "React Appの作成",
            <div className="mt-3">
              <p>
                ここではReact Appの作成を行っていきます。<br />
                はじめに、以下のコマンドを使用してReact Appを作成します。<br />
                my-appは今回作成するプロジェクト名となっているため、こちらは適宜各自のプロジェクト名に置き換えて下さい。
              </p>

              {
                CodeLayout(
                  `
  npx create-react-app my-app(プロジェクト名)
`
                )
              }

              <p>
                React Appの作成が完了すると以下のような表示がされます。<br />
              </p>

              {
                CodeLayout(
                  `
  Success! Created my-app at C:\\Users\\XXX\\Desktop\\react-app\\my-app
  Inside that directory, you can run several commands:

    npm start
      Starts the development server.

    npm run build
      Bundles the app into static files for production.

    npm test
      Starts the test runner.

    npm run eject
      Removes this tool and copies build dependencies, configuration files
      and scripts into the app directory. If you do this, you can’t go back!

  We suggest that you begin by typing:

    cd my-app
    npm start

  Happy hacking!
`
                )
              }

              <p>
                "We suggest that you begin by typing:"<br />
                つまり<br />
                "私たちは次のように入力するによってあなたが始めることを提案する"<br />
                とあるためを以下入力しましょう。
              </p>

              {
                CodeLayout(
                  `
  cd my-app
  npm start
`
                )
              }

              <p>
                ブラウザーが起動されReact Appの画面が表示されます。<br />
                画面が以下のように表示されれば環境構築は終了となります。
              </p>

              <img src={photo1} alt="環境構築完了後の画面" style={{ width: "100%" }} />
            </div>
          )
        }

        {
          ReportCommonLayout(
            "最後に",
            <p>
              「React チュートリアル① ～環境構築編～」は以上となります。<br />
              今回記事を読んで頂いた方の参考になれば嬉しいです。<br />
              また、今回の内容でわからない点や今後取り上げてほしい内容等あれば、お問い合わせからお願いします。
            </p>
          )
        }
      </div>
    </CommonLayout>
  )
}

export default Tutorial1;