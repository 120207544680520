export const Footer = () => {
  return (
    <footer className="py-3 mt-5  align-items-center justify-content-center">
      <div className="container">
        <div className="footer-info">
          <a href="/privacy_policy">プライバシーポリシー</a>
          <a href="/contact">お問い合わせ</a>
          <a href="/about">運営者情報</a>
        </div>
        <small className="">Copyright &copy; おかぴーブログ</small>
      </div>
    </footer>
  )
}

export default Footer;