import { BrowserRouter, Route, Routes } from 'react-router-dom';

// import Blank from './pages/Blank';
import * as Main from './pages/main';
import * as Application from './pages/applications';
import * as Report from './pages/reports';
import './App.css';

export const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Main.Top />} />
        <Route exact path="/privacy_policy" element={<Main.PrivacyPolicy />} />
        <Route exact path="/about" element={<Main.About />} />
        <Route exact path="/contact" element={<Main.Contact />} />

        <Route exact path="/applications" element={<Main.Application />} />
        <Route exact path="/applications/address_form" element={<Application.AddressForm />} />
        <Route exact path="/applications/tic_tac_toe_game" element={<Application.TicTacToeGame />} />
        <Route exact path="/applications/todo_list" element={<Application.TodoList />} />

        <Route exact path="/reports" element={<Main.Report />} />
        <Route exact path="/all_reports" element={<Report.AllReport />} />

        <Route exact path="/reports/tic_tac_toe_game/1" element={<Report.TicTacToeGameReport1 />} />
        <Route exact path="/reports/tic_tac_toe_game/2" element={<Report.TicTacToeGameReport2 />} />
        <Route exact path="/reports/tic_tac_toe_game/3" element={<Report.TicTacToeGameReport3 />} />
        <Route exact path="/reports/address_form/1" element={<Report.AddressFormReport1 />} />
        <Route exact path="/reports/address_form/2" element={<Report.AddressFormReport2 />} />

        <Route exact path="/reports/knowledge/local_storage" element={<Report.LocalStorage />} />
        <Route exact path="/reports/knowledge/axios" element={<Report.Axios />} />

        <Route exact path="/reports/error_resolution/1" element={<Report.ErrorResolution1 />} />

        <Route exact path="/reports/react/tutorial/1" element={<Report.Tutorial1 />} />
        <Route exact path="/reports/react/tutorial/2" element={<Report.Tutorial2 />} />
        <Route exact path="/reports/react/tutorial/3" element={<Report.Tutorial3 />} />
        <Route exact path="/reports/react/tutorial/4" element={<Report.Tutorial4 />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
