import CommonLayout from '../../layouts/CommonLayout';
import ReportCommonLayout from '../../../components/reportLayout/ReportCommonLayout';
import CodeLayout from '../../../components/CodeLayout';

export const LocalStorage = () => {
  return (
    <CommonLayout>
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-12">
            <h3 className="font-weight-light mt-4 text-black">【React, JavaScript】local storageの使い方</h3>

          </div>
        </div>

        <div className="row justify-content-center text-start mt-3">
          <div className="col-10">
            <p>React, JavaScriptでのlocal storageの使い方をまとめました。</p>
            <p>
              ブラウザー内で右クリック→検証を押すとメニューが表示されます。<br />
              このメニューのタブからApplicationを選択しStorage→Local Storage→ドメイン
              とクリックすると今の保存状態を確認することができます。
            </p>
          </div>
        </div>

        {
          ReportCommonLayout(
            "目次",
            <ul className="mt-3">
              <li>値の保存</li>
              <li>値の読み込み</li>
              <li>値の削除</li>
              <li>全データの削除</li>
            </ul>
          )
        }

        {
          ReportCommonLayout(
            "値の保存",
            <div className="mt-3">
              {
                CodeLayout(
                  `
  // 文字列の場合
  localStorage.setItem('key', 'item');

  // 配列・オブジェクト型の場合
  localStorage.setItem('key', JSON.stringify(item));
`
                )
              }
            </div>
          )
        }

        {
          ReportCommonLayout(
            "値の読み込み",
            <div className="mt-3">
              {
                CodeLayout(
                  `
  // 文字列の場合
  localStorage.getItem('key');

  // 配列・オブジェクト型の場合
  JSON.parse(localStorage.getItem('key'));
`
                )
              }
            </div>
          )
        }

        {
          ReportCommonLayout(
            "値の削除",
            <div className="mt-3">
              {
                CodeLayout(
                  `
  localStorage.removeItem('key');
`
                )
              }
            </div>
          )
        }

        {
          ReportCommonLayout(
            "全データの削除",
            <div className="mt-3">
              {
                CodeLayout(
                  `
  localStorage.clear();
`
                )
              }
            </div>
          )
        }

      </div>
    </CommonLayout>
  )
}

export default LocalStorage;