const ReportCommonLayout = (title, body) => {
  return (
    <div className="row justify-content-center mt-5">
      <div className="col-10">
        <h2><span className="badge rounded-pill">{title}</span></h2>
        <div className="col-13 mt-2">
          {body}
        </div>
      </div>
    </div>
  )
}

export default ReportCommonLayout;