import CommonLayout from '../../layouts/CommonLayout';
import ReportCommonLayout from '../../../components/reportLayout/ReportCommonLayout';
import CodeLayout from '../../../components/CodeLayout';
import React, { useState, useEffect } from 'react';

export const Tutorial4 = () => {
  return (
    <CommonLayout>
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-12">
            <h3 className="font-weight-light mt-4 text-black">React チュートリアル④<br />～useEffect編～</h3>
          </div>
        </div>

        {
          ReportCommonLayout(
            "以下の方向け",
            <ul className="mt-3">
              <li><a href='/reports/react/tutorial/1'>React チュートリアル②</a>のuseEffectについて詳しく知りたい方</li>
            </ul>
          )
        }

        {
          ReportCommonLayout(
            "参考サイト",
            <ul className="mt-3">
              <li>
                <a href="https://ja.reactjs.org/docs/hooks-effect.html">
                  副作用フック(effect hook)の利用法
                </a>
              </li>
            </ul>
          )
        }

        {
          ReportCommonLayout(
            "今回の流れ",
            <div className="mt-3">
              <ul>
                <li>React Hookの説明</li>
                <li>クラス型での使い方</li>
                <li>関数型での使い方</li>
                <li>クラス型と関数型の違い</li>
              </ul>
            </div>
          )
        }

        {
          ReportCommonLayout(
            "React Hookの説明",
            <div className="mt-3">
              <p>
                React HookとはReactの機能に接続するための関数です。<br />
                今回紹介するuseEffectは、これまでのReactの「componentDidMount・componentDidUpdate・componentWillUnmount」の機能をまとめて関数型で使用できるような機能となっています。<br />
                また、今回紹介するuseEffectのようなフックは、関数型でReact機能を使用できるように追加された機能であるためクラス内部では動作しない仕様となっています。
              </p>
            </div>
          )
        }

        {
          ReportCommonLayout(
            "クラス型での使い方",
            <div className="mt-3">
              <p>
                はじめに、関数型を紹介する前にここではクラス型での使い方を紹介します。<br />
                私自身普段はクラス型で書くことがないため、<a href="https://ja.reactjs.org/docs/hooks-effect.html">Reactの公式サイト</a>を参考にしました。<br />
                以下のコードがクラス型での使い方になります。コードの下に実際の機能もありますのでご参考にして下さい。<br />
              </p>
              {
                CodeLayout(
                  `
  import React from 'react';
  
  class ClassExample extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        count: 0
      };
    }
  
    componentDidMount() {
      document.title = \`count : \${this.state.count}\`;
    }
    componentDidUpdate() {
      document.title = \`count : \${this.state.count}\`;
    }
  
    render() {
      return (
        <p>
          count : {this.state.count}
          {'　'}
          <button onClick={() => this.setState({ count: this.state.count + 1 })}>カウント</button>
        </p>
      );
    }
  }
`
                )
              }
              <div className="row justify-content-center text-center">
                <div className="col-12">
                  <ClassExample />
                </div>
              </div>
              <p className="mt-3">
                クラス型での処理は次のような流れとなっています。<br />
                <ol>
                  <li>DOMの更新</li>
                  <li>componentDidMountの実行</li>
                  <li>「カウント」ボタンを押下 → componentDidUpdateの実行</li>
                </ol>
              </p>
            </div>
          )
        }



        {
          ReportCommonLayout(
            "関数型での使い方",
            <div className="mt-3">
              <p>
                ここでは、関数型での使い方を紹介します。<br />
                クラス型での使い方を関数型に変更すると以下のようになります。<br />
                クラス型と同様にコードの下に実際の機能もありますのでご参考にして下さい。
              </p>
              {
                CodeLayout(
                  `
  import { useState, useEffect } from 'react';

  const FunctionExample = () => {
    const [count, setCount] = useState(0);
  
    useEffect(() => {
      document.title = \`count : \${count}\`;
    });
  
    return (
      <p>
        count : {count}
        {'　'}
        <button onClick={() => setCount(count + 1)}>カウント</button>
      </p>
    )
  }
`
                )
              }
              <div className="row justify-content-center text-center">
                <div className="col-12">
                  <FunctionExample />
                </div>
              </div>
              <p>
                はじめに関数型を使用する手順は次の通りです。
                <ol>
                  <li>
                    useEffectフックの導入<br />
                    useEffectはreactパッケージの中に入っているため、
                    {
                      CodeLayout(
                        `
  import { useEffect } from 'react';
`)}
                    を追加
                  </li>
                  <li>
                    処理の作成<br />
                    useEffectフックを使用して処理を追加
                    {
                      CodeLayout(
                        `
  useEffect(() => {
    document.title = \`count : \${count}\`;
  });
`)}
                  </li>
                </ol>
                次に関数型での流れを紹介します。
                <ol>
                  <li>DOMの更新</li>
                  <li>useEffectの実行</li>
                  <li>「カウント」ボタンを押下 → useEffectの実行</li>
                </ol>
              </p>
            </div>
          )
        }

        {
          ReportCommonLayout(
            "クラス型と関数型の違い",
            <div className="mt-3">
              <p>
                ここでは、クラス型と関数型の違いを紹介します。<br />
                <br />
                はじめに記述量について着目してみましょう。<br />
                クラス型ではcomponentDidMountとcomponentDidUpdateの2回同じ処理を記述するのに対して、関数型ではuseEffectの1回記述だけとなっています。<br />
                「え、同じ処理の記述をそのまま書くだけ2回書けばいいじゃん」と思う方もいらっしゃるかと思いますが、次の2点からおススメしません。<br />
                <ol>
                  <li>同じ記述を書くときに、スペルミス等の記述ミスする可能性がある</li>
                  <li>処理を変更するために2箇所変更する必要がある</li>
                </ol>
                上記の2点は、ヒューマンエラーを防止するために大切なことなので、記述量の観点からみるとuseEffectの方がいいですね!<br />
                <br />
                次に処理について着目してみましょう。<br />
                処理については、DOMの更新→初期処理→「カウント」ボタン押下で処理となっており、クラス型でも関数型でも同じような処理となっています。<br />
                これは当然と言えば当然ですが、クラス型での処理と同様の処理を関数型でできるようにしたためですね。
                <br />
                最後に、関数型での機能について着目してみましょう。<br />
                紹介していないuseEffectの機能として「第2引数を指定」してある変数が変更された時だけ動作をさせることができます。<br />
                これはどういうことかというと、下記のような使い方をします。
              </p>
              {
                CodeLayout(
                  `
  const FunctionExample2 = () => {
    const [count1, setCount1] = useState(0);
    const [count2, setCount2] = useState(0);
                  
    useEffect(() => {
      document.title = \`count1:\${count1} count2:\${count2}\`;
    }, [count1]);
                  
    return (
    <p>
      count : {count1}
      {'　'}
      <button onClick={() => setCount1(count1 + 1)}>カウント1</button>
      {'　　'}
       count : {count2}
      {'　'}
      <button onClick={() => setCount2(count2 + 1)}>カウント2</button>
    </p>
    )
  }               
  `)}
              <p>
                これまでの記述の場合は定義した変数のどれかが更新されるたびにuseEffectの処理が実行されるのに対して、
                上記のような記述の場合は変数count1の値が更新された時にuseEffectの処理が実行されます。<br />
                「え、どういうこと?」と思った方は下に実際の機能がありますので、試してみましょう!<br/>
                「カウント1」ボタンを押下した時はcount1の値が更新されてタイトルも更新されますが、
                「カウント2」ボタンを押下した時はcount2の値は更新されますがタイトルは更新されません。<br/>
                このように第2引数を指定することで、useEffect処理をするかしないかを決めることができます。<br/>
                また、第2引数を[]にすることで、初期更新の時のみ動作するようにすることが可能です。ですので、システムの処理にあった動作にするようにしましょう！
              </p>
              <div className="row justify-content-center text-center">
                <div className="col-12">
                  <FunctionExample2 />
                </div>
              </div>
            </div>
          )
        }

        {
          ReportCommonLayout(
            "最後に",
            <p>
              「React チュートリアル③ ～useEffect編～」は以上となります。<br />
              私自身もuseEffectについて理解できていない部分も多いため今後も更新していきたいと思います。<br />
              今回記事を読んで頂いた方の参考になれば嬉しいです。<br />
              また、今回の内容でわからない点や今後取り上げてほしい内容等あれば、お問い合わせからお願いします。
            </p>
          )
        }
      </div>
    </CommonLayout>
  )
}

class ClassExample extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0
    };
  }

  componentDidMount() {
    document.title = `count:${this.state.count}`;
  }
  componentDidUpdate() {
    document.title = `count:${this.state.count}`;
  }

  render() {
    return (
      <p>
        count : {this.state.count}
        {'　'}
        <button onClick={() => this.setState({ count: this.state.count + 1 })}>カウント</button>
      </p>
    );
  }
}

const FunctionExample = () => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    document.title = `count:${count}`;
  });

  return (
    <p>
      count : {count}
      {'　'}
      <button onClick={() => setCount(count + 1)}>カウント</button>
    </p>
  )
}

const FunctionExample2 = () => {
  const [count1, setCount1] = useState(0);
  const [count2, setCount2] = useState(0);

  useEffect(() => {
    document.title = `count1:${count1} count2:${count2}`;
    // esline
  }, [count1]);

  return (
    <p>
      count : {count1}
      {'　'}
      <button onClick={() => setCount1(count1 + 1)}>カウント1</button>
      {'　　'}
      count : {count2}
      {'　'}
      <button onClick={() => setCount2(count2 + 1)}>カウント2</button>
    </p>
  )
}

export default Tutorial4;