import CommonLayout from '../../layouts/CommonLayout';
import ReportCommonLayout from '../../../components/reportLayout/ReportCommonLayout';
import CodeLayout from '../../../components/CodeLayout';

export const AddressFormReport1 = () => {
  return (
    <CommonLayout>
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-12">
            <h3 className="font-weight-light mt-4 text-black">Reactでの住所自動入力①</h3>
          </div>
        </div>

        {
          ReportCommonLayout(
            "以下の方向け",
            <ul className="mt-3">
              <li>Reactの環境構築済みの方</li>
              <li>郵便番号から住所を自動入力する処理を作成したい方</li>
              <li>Reactの記述を関数型で行いたい方</li>
            </ul>
          )
        }

        {
          ReportCommonLayout(
            "参考サイト",
            <ul className="mt-3">
              <li>
                今回は、こちらのAPIを使用しておりますので詳細を知りたい方は以下をご覧ください。<br />
                <a href="https://zipaddress.net/">電話番号-住所検索API</a>
              </li>
              <li className="mt-2">
                こちらの記事にReact, Reduxを使用した方法もありますので、参考にしたい方は以下をご覧ください。<br />
                <a href="https://qiita.com/oda3104/items/317a1309183d50061a6e?utm_campaign=popular_items&utm_medium=feed&utm_source=popular_items">React、Reduxとaxiosを使って郵便番号から住所を取得する</a>
              </li>
            </ul>
          )
        }

        {
          ReportCommonLayout(
            "作成の流れ",
            <ul className="mt-3">
              <li>入力フォームの作成</li>
              <li>郵便番号から住所の取得</li>
              <li>取得した住所の表示</li>
            </ul>
          )
        }

        {
          ReportCommonLayout(
            "入力フォームの作成",
            <div className="mt-3">
              <p>
                ここでは、入力フォームの作成を行います。<br />
                はじめに作成のためのファイルを作成します。私の場合は、"AddressForm.js"としました。<br />
                作成後は以下のように、関数型で基礎の枠組みを作成していきます。
              </p>
              {
                CodeLayout(
                  `
  export const AddressForm = () => {
    return (
      <div className="">
                
      </div>
    )
  }
                                  
  export default AddressForm;
`
                )
              }
              <p>
                次に、labelタグとinputタグを使用し、郵便番号・都道府県・市町村・町名の入力欄を作成します。
              </p>
              {
                CodeLayout(
                  `
  return (
    <div className="">
      <div className="row justify-content-center mt-5">
        <div className="col-md-4 col-4">
          <label htmlFor="zipCode" className="form-label">郵便番号</label>
          <input type="text" className="form-control" />
        </div>
      </div>
      <div className="row justify-content-center mt-5">
        <div className="col-md-4 col-4">
          <label htmlFor="prefecture" className="form-label">都道府県</label>
          <input type="text" className="form-control" />
        </div>
      </div>
      <div className="row justify-content-center mt-5">
        <div className="col-md-4 col-4">
          <label htmlFor="city" className="form-label">市町村</label>
          <input type="text" className="form-control" />
        </div>
      </div>
      <div className="row justify-content-center mt-5">
        <div className="col-md-4 col-4">
          <label htmlFor="town" className="form-label">町名</label>
          <input type="text" className="form-control" />
        </div>
      </div>
    </div>
  )
`
                )
              }
            </div>
          )
        }

        {
          ReportCommonLayout(
            "郵便番号から住所の取得",
            <div className="mt-3">
              <p>
                ここでは、郵便番号から住所の取得を行っていきます。<br />
                郵便番号欄に入力された場合に処理をする動作のため、郵便番号のinputタグにonChangeを追加します。<br />
                また、onChangeではhandleChange関数を別で定義して処理を作っていきます。
              </p>
              {
                CodeLayout(
                  `
  <input type="text" className="form-control" onChange={handleChange} />
`
                )
              }
              {
                CodeLayout(
                  `
  const handleChange = async (e) => {

  }
`
                )
              }
              <p>
                次に、handleChange関数の中の処理を作成していきます。<br />
                API通信はaxiosを使用するため、まだaxiosパッケージを追加していない方は、以下を参考に追加をしてください。<br />
              </p>
              {
                CodeLayout(
                  `
  npm install axios
  or
  yarn add axios
`
                )
              }
              <p>
                axiosパッケージを追加できたら、<a href="https://zipaddress.net/">電話番号-住所検索API</a>の
                「リクエスト/レスポンスサンプル」タブのリクエストを参考に取得した値をリクエストします。<br />
                その後、リクエストして返ってきた値をコンソールで確認します。
              </p>
              {
                CodeLayout(
                  `
  const handleChange = async (e) => {
    const res = await axios.get('https://api.zipaddress.net/?zipcode=' + e.target.value);
    console.log(res);
  }
`
                )
              }
              <p>


              </p>
            </div>
          )
        }

        {
          ReportCommonLayout(
            "取得した住所の表示",
            <div className="mt-3">
              <p>
                ここでは、取得した情報の表示を行っていきます。<br />
                始めに都道府県・市町村・町名の変数を定義します。また今回は、setStateによって値を管理するために各setState変数も定義します。<br />
                定義ができたら、それぞれのinputタグにvalueを追加し、変数を入れておきます。<br />
                この時に、郵便番号以外に入力できないようにしたい場合は、その処理も追加します。
              </p>
              {
                CodeLayout(
                  `
  const [prefecture, setPrefecture] = useState("");
  const [city, setCity] = useState("");
  const [town, setTown] = useState("");

  ・
  ・
  ・
  
  <label htmlFor="prefecture" className="form-label">都道府県</label>
  <input type="text" className="form-control" value={prefecture} disabled={true} />

  ・
  ・
  ・
  
  <label htmlFor="city" className="form-label">市町村</label>
  <input type="text" className="form-control" value={city} disabled={true} />

  ・
  ・
  ・
  
  <label htmlFor="town" className="form-label">町名</label>
  <input type="text" className="form-control" value={town} disabled={true} />
`
                )
              }
              <p>
                次に、都道府県・市町村・町名の変数に値を入れ情報を表示していきます。<br/>
                返ってきた値(私の場合だとres)を確認すると正常なレスポンスだとres.data.codeが200であることがわかるためその場合に処理をするようにします。<br/>
                レスポンスの状態を判別できたら先ほど定義したsetState関数で値を入れていきます。<br/>
                画面上で入力された郵便番号に対応した情報が入力されれば完成です。
              </p>
              {
                CodeLayout(
                  `
  const handleChange = async (e) => {
    const res = await axios.get('https://api.zipaddress.net/?zipcode=' + e.target.value);
    if(res.data.code === 200) {
      setPrefecture(res.data.data.pref);
      setCity(res.data.data.city);
      setTown(res.data.data.town);
    }
  }
`
                )
              }
            </div>
          )
        }

        {
          ReportCommonLayout(
            "最後に",
            <p>
              これでReactでの住所自動入力処理の完成になります。<br />
              <a href="/reports/address_form/2">Reactでの住所自動入力②</a>に住所自動入力の全体のコードを記載しておきますので参考にしてください。<br />
              また、わからない点や試してほしいこと等あれば、お問い合わせから入力して頂ければできる限り回答します！<br />
            </p>
          )
        }
      </div>
    </CommonLayout>
  )
}

export default AddressFormReport1;