import React, { useState } from 'react';
import CommonLayout from '../layouts/CommonLayout';
import axios from 'axios';

export const AddressForm = () => {
  const [prefecture, setPrefecture] = useState("");
  const [city, setCity] = useState("");
  const [town, setTown] = useState("");

  const handleChangeZipCode = async (e) => {
    if (e.target.value.length === 7) {
      const res = await getZipcodetoAddress(e.target.value);
      if (res !== "error") {
        setPrefecture(res.pref);
        setCity(res.city);
        setTown(res.town);
      }
    }
  }

  return (
    <CommonLayout>
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-md-7">
            <h3 className="font-weight-light mt-4 text-black">郵便番号から住所を自動入力</h3>
            <br /><br />
            本ページは以下の制限がありますので詳細は解説記事をご覧ください。
            <ul className="text-start">
              <li>郵便番号に入力できる文字数を7に設定(ハイフン抜き)</li>
              <li>郵便番号欄以外に入力できないよう設定</li>
            </ul>
          </div>
        </div>
        <div className="row justify-content-center mt-5">
          <div className="col-md-4 col-4">
            <label htmlFor="zipCode" className="form-label">郵便番号</label>
            <input type="text" className="form-control" onChange={handleChangeZipCode} maxLength={7} />
          </div>
        </div>
        <div className="row justify-content-center mt-5">
          <div className="col-md-4 col-4">
            <label htmlFor="prefecture" className="form-label">都道府県</label>
            <input type="text" className="form-control" value={prefecture} disabled={true} />
          </div>
        </div>
        <div className="row justify-content-center mt-5">
          <div className="col-md-4 col-4">
            <label htmlFor="city" className="form-label">市町村</label>
            <input type="text" className="form-control" value={city} disabled={true} />
          </div>
        </div>
        <div className="row justify-content-center mt-5">
          <div className="col-md-4 col-4">
            <label htmlFor="town" className="form-label">町名</label>
            <input type="text" className="form-control" value={town} disabled={true} />
          </div>
        </div>
      </div>
    </CommonLayout>
  )
}

const getZipcodetoAddress = async (zipCode) => {
  const res = await axios.get('https://api.zipaddress.net/?zipcode=' + zipCode)
  return res.data.code === 200 ? res.data.data : "error";
}

export default AddressForm;